@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 100 900;
	font-display: optional;
	src: url(/fonts/inter-var.ttf) format('truetype');
}

:root {
	--h-content-area: calc(100vh - theme('height.1') - theme('height.14') - theme('height.20'));
}

/* custom scrollbar */

::-webkit-scrollbar {
	width: 16px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #9ca3af;
	border-radius: 20px;
	border: 4px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #93c5fd;
}
